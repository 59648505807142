import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { faAngleDown, faAngleUp, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import {
  ComponentControllerService, ComponentResponse, DepartmentControllerService,
  DepartmentResponse,
  EmployeeControllerService,
  EmployeeResponse,
  MyProfileResponse,
  ServiceTypeResponse
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { combineLatest } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';
// import { OutstaningReportCriteria } from "src/app/model/outstanding-report-criteria.model"
// import { OutstandingReportDataSource, OutStandingReportService} from '../outstanding-report.service';
import { SearchTimesheetReportCriteria } from "src/app/model/search-timesheet-report-criteria.model";
import { ReportDataSource, TimesheetReportService } from '../services/timesheet-report.service';

@Component({
  selector: 'app-timesheet-report',
  templateUrl: './timesheet-report.component.html',
  styleUrls: ['./timesheet-report.component.scss']
})
export class TimesheetReportComponent implements OnInit, OnDestroy {

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private departmentService: DepartmentControllerService,
    private employeeService: EmployeeControllerService,
    private componentService: ComponentControllerService,
    private router: Router,
    private reportService: TimesheetReportService,
    private _httpClient: HttpClient,
    private authService: AuthService) { }

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;
  public currenDepartment: DepartmentResponse;
  public myProfile: MyProfileResponse;
  public activeEmployee = true;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public selectedItemTotal = 0;
  public page = 1;
  public pageSize = 10;
  public totalElements = 0;
  public dateCriteriaInvalid = false;

  public timesheetReportDataSource: ReportDataSource;
  public itDepartmentList: DepartmentResponse[] = [];
  public serviceTypeList: ServiceTypeResponse[] = [];
  public itEmployeeList: EmployeeResponse[] = [];
  public componentList: ComponentResponse[] = [];

  // public OutstandingReportColumns : String[] = [
  //   'createdDate','itDeptName','serviceName','taskType','taskId','mainTaskName','riskLevel','status',
  //   'createdByName','createdByUnitName','createdByDepartmentName','createdByDivisionName','projectSize','totalEstimationManday',
  //   'csEffort','solaEffort','itEstimation','qcEstimation','itOperationEstimation','uatManDay','actual']; 

  public TimesheetReportColumns : String[] = [
    'year','date','employee','taskId','mainTaskName','serviceName','mainTaskCategoryName',
    'totalEstimation','taskSize','manDay','buUser', 'buUnit', 'buDepartment','buDivision']; 
  

  public form: FormGroup = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    itDepartment: new FormControl(),
    component: new FormControl(),
    employee: new FormControl(),
    taskId: new FormControl(),
  });

  ngOnInit(): void {
    combineLatest([
      this.employeeService.getMyProfileUsingGET(),
      this.departmentService.getITDepartmentUsingGET()
    ]).subscribe(([myProfile, itDepartment]) => {
      this.myProfile = myProfile.data;
      this.itDepartmentList = itDepartment.data;
      // this.form.controls.itDepartment.setValue(this.itDepartmentList[3]);
      // this.form.controls.employee.setValue(this.myProfile.empID);
    });

    this.reportService.totalElements.subscribe((x)=> { 
      this.totalElements = x;
    });

    this.reportService.searchPageSize.next(this.pageSize);
    this.updateCriteria(this.setCriteria());
    this.reportService.startSubscribe();
    this.timesheetReportDataSource = this.reportService.reportDataSource;  
    
    this.form.controls.itDepartment.valueChanges.subscribe(
      (value: DepartmentResponse) => {
        if (value) {
          this.currenDepartment = value;
          this.getInternalStaffbyDept(value.deptID, this.activeEmployee)
          this.getService(value.deptID);
        } else {
          this.itEmployeeList = null;
          this.componentList = null;
        }
        this.form.controls.component.reset();
        this.form.controls.employee.reset();
        
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.reportService.unSubscription();
  }

  private getAllItStaff(activeEmployee: boolean){
    this.employeeService.getInternalItStaffsUsingGET(activeEmployee).subscribe((result) => {
      this.itEmployeeList = result.data;
    }); 
  }

  private getInternalStaffbyDept(deptId: string, activeEmployee: boolean){
    this.employeeService
    .getInternalEmployeeByDeptUsingGET(deptId, activeEmployee)
    .subscribe((result) => {
      this.itEmployeeList = result.data;
    });
  }

  private getService(deptID: string): void {
    this.componentService
      .getComponentsByDeptIdUsingGET(deptID)
      .subscribe((res) => (this.componentList = res.data));
  }
  
  public onSortChange(sort: Sort) {
    this.reportService.searchSort.next(sort);
}

public updateCriteria(criteria: SearchTimesheetReportCriteria) {
  this.page = 0;
    this.reportService.searchPage.next(0);
    this.reportService.searchReportCriteria.next(criteria);
    this.reportService.searchSort.next(null);
}

public redirectToCreate(): void {
  this.router.navigate(["/report"]);
}

public clear(): void {
  this.form.reset();
  this.fromDate = null;
  this.toDate = null;
}



private setCriteria(): SearchTimesheetReportCriteria {
  let criteria: SearchTimesheetReportCriteria = new SearchTimesheetReportCriteria();
  criteria.startDate = this.fromDate ? this.formatter.format(this.fromDate) : null;
  criteria.endDate = this.toDate ? this.formatter.format(this.toDate): null;
  criteria.itDeptId = this.form.value.itDepartment ? this.form.value.itDepartment.deptID: null;
  criteria.componentId = this.form.value.component ? this.form.value.component: null;
  criteria.employeeId = this.form.value.employee ? this.form.value.employee: null;
  criteria.taskId = this.form.value.taskId ? this.form.value.taskId: null;

  return criteria;
}

public search(): void {
  this.selectedItemTotal = 0;
  let serviceCriteria: SearchTimesheetReportCriteria = this.setCriteria();
  if ( serviceCriteria.itDeptId){
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.dateCriteriaInvalid = false;
      this.updateCriteria(serviceCriteria);
    }else {
      this.dateCriteriaInvalid = true;
    }
  } else {
    this.dateCriteriaInvalid = false;
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.updateCriteria(serviceCriteria);
    }
  }
}

public updatePage(): void {
    this.reportService.searchPage.next(this.page - 1);
}

public updatePageSize(size: number): void {
    this.pageSize = size;
    this.reportService.searchPageSize.next(this.pageSize);
}

public onDateSelection(date: NgbDate): void {

  if (!this.fromDate && !this.toDate) {
    this.fromDate = date;
  } else if (
    this.fromDate &&
    !this.toDate &&
    date &&
    date.after(this.fromDate)
  ) {
    this.toDate = date;
  } else {
    this.toDate = null;
    this.fromDate = date;
  }
}

public isHovered(date: NgbDate): boolean {
  this.dateCriteriaInvalid = false;
  return (
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    date.after(this.fromDate) &&
    date.before(this.hoveredDate)
  );
}

public isInside(date: NgbDate): boolean {
  return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
}

public isRange(date: NgbDate): boolean | void {
  return (
    date.equals(this.fromDate) ||
    (this.toDate && date.equals(this.toDate)) ||
    this.isInside(date) ||
    this.isHovered(date)
  );
}

public validateInput(
  currentValue: NgbDate | null,
  input: string
): NgbDate | null {
  const parsed = this.formatter.parse(input);
  this.dateCriteriaInvalid = false;
  return parsed && this.calendar.isValid(NgbDate.from(parsed))
    ? NgbDate.from(parsed)
    : currentValue;
}


private setExportCriteria(): SearchTimesheetReportCriteria {
  let criteria: SearchTimesheetReportCriteria = new SearchTimesheetReportCriteria();
  criteria.startDate = this.fromDate ? this.dateString(this.fromDate) : null;
  criteria.endDate = this.toDate ? this.dateString(this.toDate) : null;
  criteria.itDeptId = this.form.value.itDepartment ? this.form.value.itDepartment.deptID: null;
  criteria.componentId = this.form.value.buDepartment ? this.form.value.component: null;
  criteria.employeeId = this.form.value.employee ? this.form.value.employee: null;
  criteria.taskId = this.form.value.taskId ? this.form.value.taskId: null;   

  return criteria
}

private dateString(ngbDate: NgbDate): string {
  let dateStringFormat = "";
  let dateString: String = ngbDate.day < 10 ? "0"+ngbDate.day.toString() : ngbDate.day.toString();
  let montString: String = ngbDate.month < 10 ? "0"+ngbDate.month.toString() : ngbDate.month.toString();

  dateStringFormat = ngbDate.year.toString() + "-" + montString  + "-" + dateString ;
  return dateStringFormat;
}

public export (){
  let serviceCriteria: SearchTimesheetReportCriteria = this.setExportCriteria();
  if (serviceCriteria.itDeptId){
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.dateCriteriaInvalid = false;
      this.exportExcel(serviceCriteria);
    }else {
      this.dateCriteriaInvalid = true;
    }
  } else {
    this.dateCriteriaInvalid = false;
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.exportExcel(serviceCriteria);
    }
  }
}

public exportExcel(criteria: SearchTimesheetReportCriteria){
  this._httpClient.post(config.exportTimeSheetReport,criteria,
    { headers: { 'Authorization': ('bearer ' + this.authService.token) },
      responseType: 'arraybuffer'})
  .subscribe((response: any) => {
  const filename = 'outstanding-task-report.xlsx';
  this.reportService.downLoadFile(response, "application/octet-stream", filename);
  });
}

}
