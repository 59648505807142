import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { TimesheetControllerService, APIResultPageResponseListTimesheetReportResponse, TimesheetReportResponse
        } from "@set-it-workflow/set-it-workflow-ts-angular";
import { BehaviorSubject, Subscription, combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
import { SearchTimesheetReportCriteria} from 'src/app/model/search-timesheet-report-criteria.model'

@Injectable({
  providedIn: "root", 
})

export class TimesheetReportService {
  totalPages = new BehaviorSubject<number>(0);
  totalElements = new BehaviorSubject<number>(0);
  currentPage = new BehaviorSubject<number>(0);
  searchReportCriteria = new BehaviorSubject<SearchTimesheetReportCriteria>(new SearchTimesheetReportCriteria());
  searchPage = new BehaviorSubject<number>(0);
  searchPageSize = new BehaviorSubject<number>(10);
  searchSort = new BehaviorSubject<Sort>(null);
  reportDataSource = new ReportDataSource();

  private subscription: Subscription;

  constructor(private timesheetControllerService: TimesheetControllerService) {}

  public startSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(
      combineLatest([
        this.searchReportCriteria,
        this.searchPage,
        this.searchPageSize,
        this.searchSort
      ]).pipe(
        debounceTime(200),
        filter((x) => x[0] != null) 
      ).subscribe(([criteria, page, pageSize, sort]) => {
            this.getReport(
              criteria.startDate,
              criteria.endDate,
              criteria.itDeptId,
              criteria.componentId,
              criteria.employeeId,
              criteria.taskId,
              page,
              pageSize,
              sort
            ).subscribe(
              ([totalPages, totalElements, elements]: [number, number, TimesheetReportResponse[]]) => {
                this.totalPages.next(totalPages);
                this.totalElements.next(totalElements);
                this.reportDataSource.update(elements);
              }
            );
      })
    );
  }

  public unSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getReport(
    startDate: string,
    endDate: string,
    itDeptId: string,
    componentId: number,
    empId: string,
    taskId: number,
    page: number,
    pageSize: number,
    sort: Sort
  ): Observable<[number, number, TimesheetReportResponse[]]> { 
    return this.timesheetControllerService.searchTimesheetReportUsingGET( componentId, empId, endDate, itDeptId, page, pageSize, this.sortFieldBy(sort), startDate, taskId)
      .pipe(
        map((x: APIResultPageResponseListTimesheetReportResponse) => [
          x.data.totalPages,
          x.data.totalElements,
          x.data.elements])
      );
  }

  public sortFieldBy(sort: Sort): [string]{
    return sort && sort.direction && sort.active ? [this.columnToSortSymbol(sort.active) + ',' + sort.direction] : null;
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case "employee":
        return "createdBy.nameEn";
      case "taskId":
        return "taskId";
      case "date":
        return "workingDate";
    }
  }


  public downLoadFile(data: any, type: string,fileName:string) {
    let blob = new Blob([data], { type: type});
     this.saveAs(blob,fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export class ReportDataSource extends DataSource<TimesheetReportResponse> {
  data = new BehaviorSubject<TimesheetReportResponse[]>([]);

  connect(): Observable<TimesheetReportResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: TimesheetReportResponse[]): void {
    this.data.next(x);
  }
}
