import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleDown, faAngleUp, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";

import { HttpClient } from '@angular/common/http';
import { SearchDuplicateFileCriteria } from "src/app/model/search-duplicate-file-criteria.model";
import { SearchPromoteDate } from 'src/app/model/search-promote-date.model';
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';
import { DuplicateFileReportService, ReportDataSource } from '../services/duplicate-file.service';


@Component({
  selector: 'app-duplicate-file',
  templateUrl: './duplicate-file.component.html',
  styleUrls: ['./duplicate-file.component.scss']
})
export class DuplicateFileComponent implements OnInit, OnDestroy {

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private reportService: DuplicateFileReportService,
    private _httpClient: HttpClient,
    private authService: AuthService
  ) { }

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;
  public totalElements = 0;
  public dateCriteriaInvalid = false;
  public page = 1;
  public pageSize = 10;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public reportDataSource: ReportDataSource;

  public reportColumns: String[] = ['promoteId', 'subject', 'fileName', 'promoteTier', 'expectedPromoteDate'];

  public form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      // itDepartment: new FormControl(),
      endDate: new FormControl(),
      startDate: new FormControl(),
    });
    this.reportService.searchPageSize.next(this.pageSize);
    this.updateCriteria(this.setCriteria());
    this.reportService.startSubscribe();
    this.reportDataSource = this.reportService.reportDataSource;

    this.reportService.totalElements.subscribe((x) => {
      this.totalElements = x;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.reportService.unSubscription();
  }

  public redirectToCreate(): void {
    this.router.navigate(["/report"]);
  }

  public clear(): void {
    this.form.reset();
    this.fromDate = null;
    this.toDate = null;
  }

  public search(): void {
    let serviceCriteria: SearchDuplicateFileCriteria = this.setCriteria();
    if (serviceCriteria.startDate && serviceCriteria.endDate) {
      this.dateCriteriaInvalid = false;
      this.updateCriteria(serviceCriteria);
    } else {
      this.dateCriteriaInvalid = true;
    }

  }
  public updatePage(): void {
    this.reportService.searchPage.next(this.page - 1);
  }

  public updatePageSize(size: number): void {
    this.pageSize = size;
    this.reportService.searchPageSize.next(this.pageSize);
  }

  private setCriteria(): SearchDuplicateFileCriteria {
    let criteria: SearchDuplicateFileCriteria = new SearchDuplicateFileCriteria();
    criteria.endDate = this.toDate ? this.formatter.format(this.toDate) : null;
    criteria.startDate = this.fromDate ? this.formatter.format(this.fromDate) : null;
    return criteria;
  }

  public updateCriteria(criteria: SearchDuplicateFileCriteria) {
    this.page = 0;
    this.reportService.searchPage.next(0);
    this.reportService.searchReportCriteria.next(criteria);
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public isHovered(date: NgbDate): boolean {
    this.dateCriteriaInvalid = false;
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean | void {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  private setPromoteDate(): SearchPromoteDate {
    let promoteDate: SearchPromoteDate = new SearchPromoteDate();

    promoteDate.endDate = this.toDate ? this.dateString(this.toDate) : null;
    promoteDate.startDate = this.fromDate ? this.dateString(this.fromDate) : null;
    return promoteDate
  }

  private dateString(ngbDate: NgbDate): string {
    let dateStringFormat = "";
    let dateString: String = ngbDate.day < 10 ? "0" + ngbDate.day.toString() : ngbDate.day.toString();
    let montString: String = ngbDate.month < 10 ? "0" + ngbDate.month.toString() : ngbDate.month.toString();

    dateStringFormat = ngbDate.year.toString() + "-" + montString + "-" + dateString;
    return dateStringFormat;
  }

  public export() {
    let promoteDate: SearchPromoteDate = this.setPromoteDate();
    let criteria: SearchDuplicateFileCriteria = this.setCriteria();
    this._httpClient.post(config.exportDuplicateFile, promoteDate,
      {
        headers: { 'Authorization': ('bearer ' + this.authService.token) },
        responseType: 'arraybuffer'
      })
      .subscribe((response: any) => {
        const filename = 'duplicate-file-report.xlsx';
        this.reportService.downLoadFile(response, "application/octet-stream", filename);
      });

  }
}
