import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { BehaviorSubject, Subscription, combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";

import {
  EmployeeRoleReportControllerService,
  EmployeeRoleReportResponse,
  APIResultPageResponseListEmployeeRoleReportResponse
} from "@set-it-workflow/set-it-workflow-ts-angular";


@Injectable({
  providedIn: 'root'
})

export class SpecialRoleService {
  
  totalPages = new BehaviorSubject<number>(0);
  totalElements = new BehaviorSubject<number>(0);
  currentPage = new BehaviorSubject<number>(0);
  searchPage = new BehaviorSubject<number>(0);
  searchPageSize = new BehaviorSubject<number>(10);
  searchSort = new BehaviorSubject<Sort>(null);
  reportDataSource = new ReportDataSource();

  private subscription: Subscription;
  constructor(private reportService: EmployeeRoleReportControllerService) { }

  public startSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(
      combineLatest([
        this.searchPage,
        this.searchPageSize,
        this.searchSort,
      ])
        .pipe(
          debounceTime(200),
          filter((x) => x[0] != null)
        )
        .subscribe(([page, pageSize, sort]) => {
          this.getReport(page, pageSize, sort )
          .subscribe(([totalPages, totalElements, elements]: [number, number, EmployeeRoleReportResponse[]]) => {
              this.totalPages.next(totalPages);
              this.totalElements.next(totalElements);
              this.reportDataSource.update(elements);
            }
          );
        })
    );
  }

  public unSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getReport(
    page: number,
    pageSize: number,
    sort: Sort
  ): Observable<[number, number, EmployeeRoleReportResponse[]]> {
    return this.reportService
      .specialRoleUsingGET(page,pageSize,this.sortFieldBy(sort))
      .pipe(
        map((x: APIResultPageResponseListEmployeeRoleReportResponse) => [
          x.data.totalPages,
          x.data.totalElements,
          x.data.elements,
        ])
      );
  }

  public sortFieldBy(sort: Sort): [string] {
    return sort && sort.direction && sort.active
      ? [this.columnToSortSymbol(sort.active) + "," + sort.direction]
      : null;
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case 'userName':
        return 'employee.nameEn';
      case 'department':
        return 'department.deptName';
      case 'role':
        return 'role.roleName';
      case 'userStatus':
        return 'employee.isActive';
    }
  }

  public downLoadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    this.saveAs(blob, fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

}

export class ReportDataSource extends DataSource<EmployeeRoleReportResponse> {
  data = new BehaviorSubject<EmployeeRoleReportResponse[]>([]);

  connect(): Observable<EmployeeRoleReportResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: EmployeeRoleReportResponse[]): void {
    this.data.next(x);
  }
}
