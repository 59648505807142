import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { faAngleDown, faAngleUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ComponentControllerService, ComponentResponse, DepartmentControllerService, DepartmentResponse, ReportControllerService } from '@set-it-workflow/set-it-workflow-ts-angular';
import { RequestProductionDataResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/requestProductionDataResponse';
import { RequestProductionDataService } from '../services/request-production-data.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'app-request-production-data',
  templateUrl: './request-production-data.component.html',
  styleUrls: ['./request-production-data.component.scss']
})
export class RequestProductionDataComponent implements OnInit {

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;

  public tasks: RequestProductionDataResponse[] = [];
  public itDepartmentList: DepartmentResponse[] = [];
  public dataOwnerDeptList: DepartmentResponse[] = [];
  public componentList: ComponentResponse[] = [];

  public isSearch = true;
  public fromDate: NgbDate;
  public toDate: NgbDate | null = null;
  public hoveredDate: NgbDate | null = null;
  public dateCriteriaInvalid = false;

  public page = 1;
  public pageSize = 10;
  public totalElements = 0;
  public sort: Sort = {active: 'createdDate', direction: 'desc'};

  public RequestProductionDataReportColumns : String[] = [
    'taskId','taskName','itDeptName','serviceName','dataOwnerName','endOfUsage','createdDate','createdByName']; 

  public form: FormGroup = new FormGroup({
    itDepartment: new FormControl(),
    component: new FormControl(),
    createdStartDate: new FormControl(),
    createdEndDate: new FormControl(),
    dataOwner: new FormControl(),
  });

  get itDepartment(): FormControl { return this.form.controls.itDepartment as FormControl; }
  get component(): FormControl { return this.form.controls.component as FormControl; }
  get createdStartDate(): FormControl { return this.form.controls.createdStartDate as FormControl; }
  get createdEndDate(): FormControl { return this.form.controls.createdEndDate as FormControl; }
  get dataOwner(): FormControl { return this.form.controls.dataOwner as FormControl; }

  constructor(  
    private calendar: NgbCalendar,
    private reportService: ReportControllerService,
    private requestProductionDataService: RequestProductionDataService,
    private departmentService: DepartmentControllerService,
    private componentService: ComponentControllerService,
    public formatter: NgbDateParserFormatter,
    private _httpClient: HttpClient,
    private authService: AuthService
  ) { }
  
  ngOnInit(): void {
    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.itDepartmentList = result.data;
    });

    this.departmentService.getDepartmentByTreeLevelUsingGET(5).subscribe((result) => {
      this.dataOwnerDeptList = result.data;
    });

    this.itDepartment.valueChanges.subscribe((value) => {
        if (value) {
          this.getComponentByDeptId(value);
        } else {
          this.componentList = [];
        }
        this.form.controls.component.reset();
      }
    );
  }

  private getTaskRequestProductionData(): void {
    this.reportService.reportRequestProductionDataUsingGET(
      this.component.value, this.dataOwner.value, this.createdEndDate.value, this.itDepartment.value,
      this.page - 1, this.pageSize, null, this.createdStartDate.value
    ).subscribe(res => {
      this.tasks = res.data.elements;
      this.totalElements = res.data.totalElements;
    });
  }

  public export() {
  const queryParams: { [k: string]: any } = {};
    if (this.itDepartment.value) {
      queryParams.itDeptID = this.itDepartment.value;
    }
    if (this.component.value) {
      queryParams.componentId = this.component.value;
    }
    if (this.dataOwner.value) {
      queryParams.dataOwnerId = this.dataOwner.value;
    }
    if (this.createdStartDate.value) {
      queryParams.startDate = this.createdStartDate.value;
    }
    if (this.createdEndDate.value) {
      queryParams.endDate = this.createdEndDate.value;
    }
    this._httpClient.get(config.exportRequestProductionDataReport,
      {
        headers: { 'Authorization': ('bearer ' + this.authService.token) },
        responseType: 'arraybuffer',
        params: queryParams
      })
      .subscribe((response: any) => {
        const filename = 'request-production-data-report.xlsx';
        this.requestProductionDataService.downLoadFile(response, "application/octet-stream", filename);
      });
  }


  public clear(): void {
    this.form.reset();
    this.fromDate = null;
    this.toDate = null;
    this.tasks = [];
    this.page = 1;
    this.totalElements = 0;
    this.componentList = [];
  }

  public search(): void {
    this.createdStartDate.setValue(this.formatter.format(this.fromDate));
    this.createdEndDate.setValue(this.formatter.format(this.toDate));

    if (!this.form.invalid) {
      this.page = 1;
      this.getTaskRequestProductionData();
    }
  }

  public updatePage(): void {
    this.getTaskRequestProductionData();

    // this.reportService.searchPage.next(this.page - 1);
  }

  public updatePageSize(size: number): void {
    this.pageSize = size;
    this.page = 1;
    this.getTaskRequestProductionData();
      // this.pageSize = size;
      // this.reportService.searchPageSize.next(this.pageSize);
  }

  public onSortChange(sort: Sort) {
    this.sort = sort
    this.getTaskRequestProductionData();
    // this.reportService.searchSort.next(sort);
  }

  private getComponentByDeptId(deptId: string): void {
    this.componentService.getComponentsByDeptIdUsingGET(deptId).subscribe(res => {
      this.componentList = res.data;
    });
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    this.dateCriteriaInvalid = false;
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  public isHovered(date: NgbDate): boolean {
    this.dateCriteriaInvalid = false;
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean | void {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

}
