import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { BehaviorSubject, Subscription, combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";

import { OutStandingReportControllerService, OutStandingReportResponse, APIResultPageResponseListOutStandingReportResponse
        } from "@set-it-workflow/set-it-workflow-ts-angular";
import { OutstaningReportCriteria } from "src/app/model/outstanding-report-criteria.model";

@Injectable({
  providedIn: "root", 
})

export class OutStandingReportService {
  totalPages = new BehaviorSubject<number>(0);
  totalElements = new BehaviorSubject<number>(0);
  currentPage = new BehaviorSubject<number>(0);
  searchReportCriteria = new BehaviorSubject<OutstaningReportCriteria>(new OutstaningReportCriteria());
  searchPage = new BehaviorSubject<number>(0);
  searchPageSize = new BehaviorSubject<number>(10);
  searchSort = new BehaviorSubject<Sort>(null);
  outstandingDataSource = new OutstandingReportDataSource();

  private subscription: Subscription;

  constructor(private OutstandingReportService: OutStandingReportControllerService) {}

  public startOutStandingReportSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(
      combineLatest([
        this.searchReportCriteria,
        this.searchPage,
        this.searchPageSize,
        this.searchSort
      ]).pipe(
          debounceTime(200),
          filter((x) => x[0] != null)
        ).subscribe(([searchReportCriteria, page, pageSize, sort]) => {
            this.getOutStandingReport(
              searchReportCriteria.itDeptId,
              searchReportCriteria.deptId,
              searchReportCriteria.endDate,
              searchReportCriteria.startDate,
              page,
              pageSize,
              sort
            ).subscribe(
              ([totalPages, totalElements, elements]: [
                number,
                number,
                OutStandingReportResponse[]
              ]) => {
                this.totalPages.next(totalPages);
                this.totalElements.next(totalElements);
                this.outstandingDataSource.update(elements);
              }
            );
        })
    );
  }

  public unSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getOutStandingReport(
    itDeptId: string,
    deptId: string,
    endDate: string,
    startDate: string,
    page: number,
    pageSize: number,
    sort: Sort,
  ): Observable<[number, number, OutStandingReportResponse[]]> { 
    return this.OutstandingReportService.searchOutstandingReportUsingGET(deptId, endDate, itDeptId, page, pageSize, this.sortFieldBy(sort), startDate)
    .pipe(
        map((x: APIResultPageResponseListOutStandingReportResponse) => [
          x.data.totalPages,
          x.data.totalElements,
          x.data.elements ])
    );
  }

  public sortFieldBy(sort: Sort): [string]{
    return sort && sort.direction && sort.active ? [this.columnToSortSymbol(sort.active) + ',' + sort.direction] : null;
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case "createdDate":
        return "createdDate";
      case "buDepartment":
        return "taskDetail.toSupportBusinessDepartment";
      case "itDepartment":
        return "taskDetail.department";
      case "componentName":
        return "component.componentName";
      case "taskId":
        return "taskId";
      case "mainTaskName":
        return "taskName";
    }
  }

  public downLoadFile(data: any, type: string,fileName:string) {
    let blob = new Blob([data], { type: type});
     this.saveAs(blob,fileName);
  }


  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export class OutstandingReportDataSource extends DataSource<OutStandingReportResponse> {
  data = new BehaviorSubject<OutStandingReportResponse[]>([]);

  connect(): Observable<OutStandingReportResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: OutStandingReportResponse[]): void {
    this.data.next(x);
  }
}
